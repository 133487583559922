import { useState, forwardRef } from "react";
import PropTypes from "prop-types";

// @mui material components
//import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
//import Autocomplete from "@mui/material/Autocomplete";

// Otis Kit PRO components
import MKBox from "components/MKBox";
//import MKInput from "components/MKInput";

// DUMMY DATA
import MKTypography from "components/MKTypography";
import { Link } from "react-router-dom";

const Expertos = forwardRef(({ estado, ciudad, sucursal, resultadosAsesores }, ref) => {
  //const [expertos,setExpertos] = useState([]);
  const [pagina, setPagina] = useState(1);

  const itemsPerPage = 4;

  // Calculate the indices for the slice
  const indexOfLastItem = pagina * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current items for the page
  const expertos =
    resultadosAsesores && resultadosAsesores.slice(indexOfFirstItem, indexOfLastItem);

  // Function to change the page
  const paginate = (pagina) => setPagina(pagina);

  return (
    <MKBox
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {ciudad && (
        <MKTypography variant="h3" sx={{ color: "#28558f", ml: 2, pl: 1, mb: 0, pb: 0 }}>
          Nuestros Expertos en {ciudad}
        </MKTypography>
      )}
      <MKTypography variant="subtitle1" sx={{ color: "#333333", ml: 2, pl: 1, mb: 10 }}>
        Estamos aquí para ayudarte a proteger lo que más te importa
      </MKTypography>

      <MKBox
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MKBox
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          {expertos?.length == 0 && <p>De momento no tenemos asesores aquí..</p>}
          {expertos?.map((item) => (
            <MKBox
              key={item.id}
              style={{
                display: "flex",
                flexDirection: "row",
                minWidth: "40%",
                maxWidth: "80%",
                p: 2,
                m: 4,
                gap: 10,
                alignItems: "flex-end",
              }}
            >
              <Link to={"/asesor/" + estado + "/" + ciudad + "/" + sucursal + "/" + item.Nombre}>
                <MKBox
                  component="img"
                  src={`${item.Imagen_Url}`}
                  alt="image"
                  borderRadius="md"
                  maxWidth="150px"
                  maxHeight="150px"
                  width="100%"
                />
              </Link>
              <MKBox style={{ display: "flex", flexDirection: "column" }}>
                <MKTypography variant="h4" color="black">
                  {item.Nombre}
                </MKTypography>
                <MKTypography variant="body1" color="#28558f">
                  {item.Email}
                </MKTypography>
                {/* <MKTypography variant="sutitle2" color="#33333">
                  {item.phone}
                </MKTypography> */}
                <MKTypography variant="sutitle2" color="black">
                  {item.Ciudad}
                </MKTypography>
              </MKBox>
            </MKBox>
          ))}
        </MKBox>
        <MKBox
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          {/* Render pagination buttons */}
          {Array.from({ length: Math.ceil(resultadosAsesores?.length / itemsPerPage) }, (_, i) => (
            <p
              key={i}
              onClick={() => paginate(i + 1)}
              style={{
                borderRadius: 100,
                backgroundColor: i + 1 == pagina ? "#28558f" : "transparent",
                color: i + 1 == pagina ? "white" : "#28558f",
                border: i + 1 == pagina ? "transparent" : "1px solid #ccc",
                padding: 10,
                margin: 10,
                width: 50,
                height: 50,
                textAlign: "center",
              }}
            >
              {i + 1}
            </p>
          ))}
        </MKBox>
      </MKBox>
    </MKBox>
  );
});

Expertos.propTypes = {
  estado: PropTypes.string.isRequired,
  ciudad: PropTypes.string.isRequired,
  sucursal: PropTypes.string.isRequired,
  // busqueda: PropTypes.string.isRequired,
  resultadosAsesores: PropTypes.array.isRequired,
};

export default Expertos;
