/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "promessa/components/DefaultNavbar";
import DefaultFooter from "promessa/components/DefaultFooter";

// Routes
import routes from "promessa/routes";
import footerRoutes from "promessa/footer.routes";

// Images
import logo from "promessa/assets/images/icon.svg";
import bgImage from "promessa/assets/images/banner-2_azul.jpg";
import bgImageMobile from "promessa/assets/images/mobile/banner_oficinas_mobile.jpg";

import { Card } from "@mui/material";

// Estados de la República
//import estadosJson from "promessa/assets/estados_federales.json";
//import ciudadesJson from "promessa/assets/ciudades.json";

import Expertos from "promessa/pages/OficinaDetalles/sections/Expertos";
import { useEffect, useState, useRef } from "react";
import { /* Link */ useParams } from "react-router-dom";

//import vermasText from "promessa/assets/images/boton ver mas.png";

//import oficinasChihuahua from "promessa/assets/Chihuahua.json";
//import oficinasTamaulipas from "promessa/assets/Tamaulipas.json";

//import asesoresChihuahua from "promessa/assets/asesores_chihuahua.json";
//import asesoresTamaulipas from "promessa/assusets/asesores_tamaulipas.json";

import { PropTypes } from "prop-types";
//import axios from "axios";

import Buscador from "./components/Buscador";
import useStore from "promessa/store";

function OficinaDetalles() {
  const { estado, ciudad } = useParams();
  const {
    sucursalesEnCiudad,
    //asesoresEnSucursal,
    //asesoresEnSucursalPorNombre,
    cargarSucursalesEnCiudad,
    cargarAsesoresEnSucursal,
    //cargarAsesoresEnSucursalPorNombre,
  } = useStore();

  const [sucursal, setSucursal] = useState("");
  //const [expertosAparecen, setExpertosAparecen] = useState(false);
  const [resultadosAsesores, setResultadosAsesores] = useState([]);
  const [todosAsesores, setTodosAsesores] = useState([]); // Guardar todos los asesores
  const expertosRef = useRef(null);

  useEffect(() => {
    cargarSucursalesEnCiudad(ciudad);
  }, []);

  useEffect(() => {
    if (resultadosAsesores?.length > 0 && expertosRef.current != null) {
      expertosRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [resultadosAsesores]);

  /*   const handleClickParaOficinaConId = (_oficina_Id) => {
    setExpertosAparecen(false);
    setTimeout(() => {
      setRenderKey((prevKey) => prevKey + 1);

      const llave = estado + "," + ciudad;
      const todosAsesoresOficina = asesoresEnCiudad[llave]?.filter(
        (asesor) => _oficina_Id == asesor.Oficina_Id
      );


      setResultadosAsesores(todosAsesoresOficina || []);

      setExpertosAparecen(true);
    }, 100);
  }; 
  */

  const handleClickParaSucursal = async (paramSucursal) => {
    setSucursal(paramSucursal);
    const asesores = await cargarAsesoresEnSucursal(estado, ciudad, paramSucursal);
    setResultadosAsesores(asesores);
    setTodosAsesores(asesores);
    //setRenderKey((prevKey) => prevKey + 1);
  };

  return (
    <div style={{ width: "100%" }}>
      <DefaultNavbar routes={routes} sticky transparent light />
      <MKBox
        minHeight="20vh"
        maxHeight="100vh"
        width="100%"
        sx={{
          backgroundColor: "#225399",
          backgroundImage: { xs: ``, sm: `url(${bgImage})`, md: `url(${bgImage})` },
          backgroundSize: { xs: "cover", md: "cover" },
          backgroundPosition: { xs: "center center", md: "top" },
          backgroundRepeat: "no-repeat",
          display: "flex",
          placeItems: { xs: "flex-start", md: "center" },
          paddingTop: { xs: "120px", md: 0 }, // Adjust paddingTop to avoid conflict with navbar on mobile
          paddingBottom: { xs: "100px", md: "" },
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "center" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            sx={{
              textAlign: { xs: "left", md: "left" },
              display: "flex",
              flexDirection: "column",
              paddingLeft: { xs: "16px", md: 0 }, // Add paddingLeft for mobile
            }}
          >
            <Grid
              item
              textAlign={{ xs: "center", md: "left" }}
              alignItems={{ xs: "center", md: "flex-start" }}
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                marginTop: "100px",
              }}
            >
              <MKTypography
                display={{ xs: "none", md: "inline-block" }}
                variant="h1_it"
                color="white"
                className="text-container"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  marginRight: 1,
                })}
              >
                Promessa
              </MKTypography>
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("sm")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                en{" "}
                <MKTypography
                  display={{ xs: "none", md: "inline-block" }}
                  variant="h1"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  {ciudad}
                </MKTypography>
                <MKTypography
                  display={{ xs: "inline-block", md: "none" }}
                  variant="h1"
                  color="white"
                  className="text-container"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  {ciudad}
                </MKTypography>
              </MKTypography>
              <MKBox
                component="img"
                src={bgImageMobile}
                display={{ xs: "block", sm: "none", md: "none" }}
                width="100%"
                height="auto"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          backgroundColor: "white",
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          alignItems: "center",
          //mt: { xs: 0, md: -40 },
          mx: 0,
        }}
      >
        <MKBox
          pt={1}
          px={0}
          sx={{
            mx: { xs: 1, md: 0 },
            mb: 2,
            pt: 1,
            flexDirection: "row", // Mantén flexDirection en "row" para todos los tamaños
            flexWrap: "wrap", // Permitir que los elementos se envuelvan
            backgroundColor: "white",
            flex: 1,
            display: "flex",
            alignItems: "stretch",
            justifyContent: { xs: "center", md: "flex-start" }, // Ajustar alineación según el tamaño
          }}
        >
          {sucursalesEnCiudad[ciudad]?.map((sucursal) => (
            <MatrizCard
              key={sucursal.id}
              estado={estado}
              oficina={sucursal}
              onClick={() => handleClickParaSucursal(sucursal.Nombre)}
            />
          ))}
        </MKBox>

        {/*  <Link to="/oficinas/estados">
          <MKButton
            variant="contained"
            color="warning"
            size="large"
            sx={{
              paddingHorizontal: 4,
              paddingVertical: 1,
              width: "auto",
              minWidth: "fit-content",
              maxWidth: "fit-content",
              flexShrink: 0,
              color: "#4a2c00",
              fontSize: "0.7em",
              fontWeight: "bold",
            }}
            //          onClick={agregarCuatroOficinas}
          >
            <img src={vermasText} style={{ width: "60px" }} />
          </MKButton>
        </Link> */}
        <div style={{ marginBottom: 20 }}></div>
      </Card>
      <Buscador
        /* estadoAsesoresData={estado == "Chihuahua" ? asesoresChihuahua : asesoresTamaulipas} */
        //ciudad={ciudad}
        //estado={estado}
        //sucursal={sucursal}
        asesores={resultadosAsesores}
        setResultadosAsesores={setResultadosAsesores}
        todosAsesores={todosAsesores}
        //setAsesores={setResultadosAsesores}
      />
      {resultadosAsesores?.length > 0 ? (
        <Expertos
          ref={expertosRef}
          ciudad={ciudad}
          estado={estado}
          sucursal={sucursal}
          resultadosAsesores={resultadosAsesores}
        />
      ) : (
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            mb: 6,
          }}
        >
          <MKTypography>Selecciona una sucursal para ver los asesores.</MKTypography>
        </MKBox>
      )}
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </div>
  );
}

// eslint-disable-next-line react/prop-types
function MatrizCard({ oficina, estado, onClick }) {
  return (
    <Card
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", // Align items vertically centered
        backgroundColor: "#ebeced",
        flexWrap: "wrap",
        p: 2,
        m: 1,
        flexGrow: 1,
        flexBasis: "120px",
        lineHeight: 1.1,
        gap: 1,
        //maxWidth: { xs: "100%", sm: "40%", md: "30%", lg: "20%" },
      }}
    >
      <MKBox
        component="img"
        sx={{
          height: 24,
          width: 24,
          mt: 2,
        }}
        alt="Promessa logo"
        src={logo}
      />
      <MKTypography variant="subtitle">{oficina.Nombre}</MKTypography>
      <MKTypography textAlign="center" variant="subtitle" sx={{ flexGrow: 1 }}>
        {oficina["Direccion"]}
      </MKTypography>
      <MKTypography variant="subtitle">
        {oficina.Ciudad}, {estado}
      </MKTypography>
      <MKTypography variant="subtitle">Tel. {oficina.Telefono_1}</MKTypography>
      <MKTypography
        variant="subtitle"
        style={{ visibility: oficina.Telefono_2 ? "visible" : "hidden" }}
      >
        Tel. {oficina.Telefono_2}
      </MKTypography>

      <MKBox
        style={{
          flexDirection: "row",
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <MKButton variant="contained" color="info" size="small">
          8:30 AM
        </MKButton>
        <MKButton variant="contained" color="info" size="small">
          8:30 PM
        </MKButton>
      </MKBox>
    </Card>
  );
}

MatrizCard.propTypes = {
  estado: PropTypes.string.isRequired,
  oficina: PropTypes.object.isRequired,
  ciudad: PropTypes.string,
};

export default OficinaDetalles;
