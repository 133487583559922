/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Card from "@mui/material/Card";
//import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "promessa/components/DefaultNavbar";
import DefaultFooter from "promessa/components/DefaultFooter";

// Coworking page sections
//import Information from "pages/LandingPages/Coworking/sections/Information";
//import Testimonials from "pages/LandingPages/Coworking/sections/Testimonials";
//import AboutUs from "pages/LandingPages/Coworking/sections/AboutUs";
//import Places from "pages/LandingPages/Coworking/sections/Places";

// Routes
import routes from "promessa/routes";
import footerRoutes from "promessa/footer.routes";

import promessaImg from "promessa/assets/images/icon_alone.png";
import tiraImg from "promessa/assets/images/adf.jpg";
//import fondoImg from "promessa/assets/images/mobile/asesor_fondo.png";
import cotizaText from "promessa/assets/images/boton cotiza.png";
import cotizaBlancoText from "promessa/assets/images/boton cotiza blanco.png";

//import bgImage from "promessa/assets/images/banner_home.jpg";
import { PropTypes } from "prop-types";

import breakpoints from "assets/theme/base/breakpoints";
import { useParams } from "react-router-dom";
import useStore from "promessa/store";

//import Card from "assets/theme/components/card";
//import { Box } from "@mui/material";

function Asesor() {
  const [mobile, setMobile] = useState(false);
  const { estado, ciudad, sucursal, nombre } = useParams();
  const { cargarAsesoresEnSucursalPorNombre } = useStore();
  const [asesor, setAsesor] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    cargarAsesoresEnSucursalPorNombre(estado, ciudad, sucursal, nombre).then((info) => {
      if (info && info.length > 0) {
        setAsesor(info[0]);
        //console.log(info[0]);
        //console.info(asesor);
        setVideoUrl(info[0].Video_Url);
      } else {
        console.error("nope");
        //location.pathname = "/";
      }
    });

    function displayMobile() {
      if (window.innerWidth < breakpoints.values.md) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }
    window.addEventListener("resize", displayMobile);
    displayMobile();

    return () => window.removeEventListener("resize", displayMobile);
  }, [nombre]);

  return (
    <div style={{ width: "100%" }}>
      <DefaultNavbar routes={routes} sticky transparent light={false} />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          // eslint-disable-next-line no-undef
          /* backgroundImage: `url(${process.env.PUBLIC_URL}/assets/expertos_banners/banner-socios-01.jpg)`, */
          backgroundImage: `url(${asesor?.Imagen_BG_Url})`,
          backgroundSize: "cover",
          backgroundPosition: { xs: "calc(70%) top", md: "top" },
          backgroundRepeat: "no-repeat",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            alignItems={{ xs: "left", md: "left" }}
            sx={{
              textAlign: { xs: "left", md: "left" },
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <MKBox
              p={2}
              mt={{ xs: 5, md: 0 }}
              backgroundColor={{
                xs: "rgba(255,255,255,0.5) !important",
                md: "transparent !important",
              }}
            >
              <MKTypography
                variant="h1"
                color="#28558f"
                className="text-container"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Bienvenido
              </MKTypography>
              <MKTypography variant="h3" color="#205599ff" mt={4} pr={{ md: 12, lg: 24, xl: 0 }}>
                Soy {asesor?.Nombre} <br></br>tu asesor de seguros Promessa
              </MKTypography>

              <MKBox mt={4} sx={{ display: "flex", justifyContent: { xs: "center", md: "start" } }}>
                <MKButton
                  variant="contained"
                  color="warning"
                  size="large"
                  sx={{
                    paddingHorizontal: "16px",
                    paddingVertical: "32px",
                    width: "auto",
                    minWidth: "fit-content",
                    maxWidth: "fit-content",
                    flexShrink: 0,
                    color: "#4a2c00",
                  }}
                >
                  <img src={cotizaText} style={{ width: "60px" }} />
                </MKButton>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        sx={{
          //backgroundImage: { xs: `url(${fondoImg})`, md: "url('')" },
          backgroundSize: "200%",
          backgroundPosition: "center",
          backgroundRepeat: "repeat-y",
        }}
      >
        <MKBox
          p={5}
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
          backgroundColor={{
            xs: "rgba(255,255,255,0.5) !important",
            md: "transparent !important",
          }}
        >
          {mobile && <MKTypography variant="subtitle1">VIDEO DE BIENVENIDA</MKTypography>}
          {videoUrl ? (
            <video id="ytplayer" width={mobile ? "100%" : "480"} height="360" controls={true}>
              {/* <source src={asesor?.Video_Url.replace(/\\\\/g, "/")} type="video/mp4" /> */}
              <source src={videoUrl} type="video/mp4" />
            </video>
          ) : (
            <p>Cargando video..</p>
          )}
          <MKBox
            sx={{
              maxWidth: "100%",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              mt: 2,
              width: mobile ? "80%" : "40%",
              flexWrap: "wrap",
            }}
          >
            <MKTypography
              variant="subtitle1"
              color="#28558f"
              alignSelf={mobile ? "center" : "flex-start"}
            >
              BIOGRAFIA
            </MKTypography>
            <MKTypography variant="h3" textAlign="left">
              {/* Comprometido con la seguridad financiera y el bienestar de mis clientes */}
            </MKTypography>
            <MKTypography variant="subtitle2" color="#eee" sx={{ mt: 4 }} textAlign="justify">
              {asesor?.Biografia}
              {/* Con [X] años de experiencia en el sector de seguros, me enorgullece ofrecer un
              servicio personalizado y confiable a cada uno de mis clientes. Mi objetivo es
              brindarte tranquilidad y la protección que necesitas para enfrentar cualquier
              situación. */}
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox
          backgroundColor={{
            xs: "rgba(122,122,122,0.1) !important",
            md: "rgba(122,122,122,0.1) !important",
          }}
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
          pt={6}
          mt={6}
        >
          <MKTypography variant="h2" mt={2} mb={2} textAlign="center">
            Nuestra Promessa para tu seguridad
          </MKTypography>
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <MKBox
              id="b1"
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              mr={{ xs: 0, md: 7 }}
            >
              <MKBox
                component="img"
                src={promessaImg}
                style={{ width: 50, height: 50, marginLeft: -18 }}
              />
              <MKTypography variant="subtitle1">Seguro de Gastos Médicos</MKTypography>
              <MKTypography variant="subtitle2">Cobertura integral para tratamientos</MKTypography>
              <MKTypography variant="subtitle2">y emergencias médicas</MKTypography>
            </MKBox>
            <MKBox
              id="b2"
              style={{ display: "flex", flexDirection: "column", maxWidth: "40%", margin: 8 }}
            >
              <MKBox
                component="img"
                src={promessaImg}
                style={{ width: 50, height: 50, marginLeft: -18 }}
              />
              <MKTypography variant="subtitle1">Seguro de Vida</MKTypography>
              <MKTypography variant="subtitle2">Garantiza el futuro de los que más</MKTypography>
              <MKTypography variant="subtitle2">quieres</MKTypography>
            </MKBox>
          </MKBox>
          <MKTypography variant="h2" mt={5} mb={2}>
            Preguntas frecuentes
          </MKTypography>
          <MKTypography variant="h4" color="#ddd" style={{ width: "80%" }} textAlign="center">
            Encuentra respuestas a las dudas más comunes sobre nuestros servicios y productos de
            seguros
          </MKTypography>
          <MKBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
            width={{
              xs: "80%",
              md: "60%",
            }}
          >
            <Collapsable
              pregunta="¿Qué tipos de seguros ofrecen en Promessa?"
              respuesta="Seguro de Gastos Médicos y Seguro de Vida."
            />

            <Collapsable
              pregunta="¿Cómo puedo cotizar un seguro?"
              respuesta="Siempre estamos disponibles para asistencia personalizada, un agente de Promessa Seguros puede ayudarte a entender mejor las opciones y conseguir la mejor cobertura para tus necesidades. "
            />

            <Collapsable pregunta="¿Qué debo hacer en caso de un siniestro?">
              <div>
                <span>
                  Es importante seguir ciertos pasos para asegurarte de que el proceso de
                  reclamación sea lo más fluido posible.
                </span>
                <ul>
                  <li>
                    <b>Mantén la calma y asegúrate que todos estén a salvo.</b>

                    <span>Si hay heridos solicita asistencia médica de inmediato.</span>
                  </li>

                  <li>
                    <b>
                      Recopila información. Toma fotos del lugar del accidente, los daños, y
                      cualquier otro detalle relevante.
                    </b>
                  </li>

                  <li>
                    <b>
                      Notifica a tu aseguradora. La mayoría de las aseguradoras tienen un tiempo
                      límite para reportar un siniestro, que suele ser entre 24 y 48 horas. Llama a
                      la línea de atención de siniestros.
                    </b>
                  </li>

                  <li>
                    <b>Sigue las instrucciones de la aseguradora.</b>
                    <span>
                      Te guiarán sobre los próximos pasos, que pueden incluir el envío de un
                      ajustador para evaluar los daños o la solicitud de documentación adicional.
                    </span>
                  </li>

                  <li>
                    <b>*Proporciona documentación requerida.</b>
                    <span>
                      Esto puede incluir formularios de reclamación, fotografías, facturas, reportes
                      policiales, entre otros. Asegúrate de que todos los documentos estén completos
                      y correctamente llenados.
                    </span>
                  </li>

                  <li>
                    <b>Colabora durante la investigación.</b>
                    <span>
                      Si la aseguradora realiza una investigación, sé transparente y proporciona
                      toda la información solicitada.
                    </span>
                  </li>
                </ul>
              </div>
            </Collapsable>
            {/* 
            <Collapsable
              pregunta="¿Dónde están ubicadas sus oficinas?"
              respuesta="Donec eget felis velit. Maecenas laoreet vestibulum mattis. Fusce eros ex, pharetra in leo non, consequat egestas magna. Curabitur congue dictum lorem, et fringilla diam varius sed."
            />

            <Collapsable
              pregunta="¿Cómo puedo contactar a un asesor de Promessa?"
              respuesta="Mauris scelerisque massa ut ultrices eleifend. Donec suscipit mauris tincidunt, porttitor erat ac, molestie elit. Vestibulum rutrum sagittis turpis, ac tincidunt nisl sodales non. Quisque ex mi, commodo pulvinar ipsum eu, sollicitudin viverra erat. Donec mattis congue eros. Donec vel lectus et mi fermentum consectetur condimentum commodo massa. Maecenas odio nibh, scelerisque et aliquam et, facilisis ut nisl. Nam ullamcorper ex in tristique iaculis. Etiam euismod ultricies aliquam."
            /> */}
          </MKBox>
        </MKBox>

        {!mobile ? (
          <MKBox
            sx={{
              display: "flex",
              width: "100%",
              height: "200px",
              backgroundImage: "url(" + tiraImg + ")",
              backgroundSize: "cover",
              backgroundPosition: "top",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <MKBox sx={{ display: "flex", flexDirection: "column" }}>
              <MKTypography color="white" variant="h4">
                ¡Obtén tu protección ahora mismo!
              </MKTypography>
              <MKTypography color="white" variant="h5">
                Haz clic en el botón para obtener más información
              </MKTypography>
            </MKBox>

            <MKButton
              variant="contained"
              color="warning"
              size="large"
              sx={{
                paddingHorizontal: "16px",
                paddingVertical: "32px",
                width: "auto",
                minWidth: "fit-content",
                maxWidth: "fit-content",
                flexShrink: 0,
                color: "white",
              }}
            >
              <img src={cotizaBlancoText} style={{ width: "60px" }} />
            </MKButton>
          </MKBox>
        ) : (
          <MKBox
            sx={{
              display: "flex",
              width: "100%",
              height: "200px",
              backgroundImage: "url(" + tiraImg + ")",
              backgroundSize: "cover",
              backgroundPosition: "top",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              px: 2,
            }}
          >
            <MKBox sx={{ display: "flex", flexDirection: "column" }}>
              <MKTypography color="white" variant="h4">
                ¡Obtén tu protección ahora mismo!
              </MKTypography>
              <MKTypography color="white" variant="h5">
                Haz clic en el botón para obtener más información
              </MKTypography>
            </MKBox>

            <MKButton
              variant="contained"
              color="warning"
              size="large"
              sx={{
                paddingHorizontal: "16px",
                paddingVertical: "32px",
                width: "auto",
                minWidth: "fit-content",
                maxWidth: "fit-content",
                flexShrink: 0,
                color: "white",
              }}
            >
              <img src={cotizaBlancoText} style={{ width: "60px" }} />
            </MKButton>
          </MKBox>
        )}
      </MKBox>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </div>
  );
}

function Collapsable({ pregunta, respuesta, children }) {
  const [abierto, setAbierto] = useState(false);

  return (
    <div style={{ width: "100%", borderBottom: "1px solid #444" }}>
      <MKBox
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setAbierto(!abierto)}
      >
        <MKTypography>{pregunta}</MKTypography>
        <MKTypography variant="h1" p={2}>
          {abierto ? "-" : "+"}
        </MKTypography>
      </MKBox>
      {abierto && (
        <MKBox
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            m: 1,
          }}
        >
          {respuesta || children}
        </MKBox>
      )}
    </div>
  );
}

Collapsable.propTypes = {
  pregunta: PropTypes.string.isRequired,
  respuesta: PropTypes.string.isRequired,
  children: PropTypes.array,
};
export default Asesor;
