//import { Navigate } from "react-router-dom";
import Asesor from "./pages/Asesor";
import PromessaHome from "./pages/Home";
import LoginForm from "./pages/IniciarSesion";
import OficinaDetalles from "./pages/OficinaDetalles";
import Oficinas from "./pages/Oficinas";
import AvisoDePrivacidad from "./paginas-estaticas/AvisoDePrivacidad";
import SeguroDeAuto from "./paginas-estaticas/SeguroDeAuto";
import SeguroDeVida from "./paginas-estaticas/SeguroDeDanos";
import SeguroDeDanos from "./paginas-estaticas/SeguroDeDanos";
import TerminosYCondiciones from "./paginas-estaticas/TerminosYCondiciones";

const routes = [
  {
    name: "Inicio",
    route: "/inicio",
    component: <PromessaHome />,
    key: 1,
    inMenu: true,
  },
  {
    name: "Oficinas",
    route: "/oficinas/:accion",
    component: <Oficinas />,
    key: 2,
    inMenu: true,
  },
  {
    name: "Iniciar sesión",
    //href: "https://app.promessa.mx",
    route: "/login",
    component: <LoginForm />,
    // component: <Oficinas />,
    key: 3,
    inMenu: true,
  },
  {
    name: "OficinaDetalles",
    route: "/oficina-detalles/:estado/:ciudad",
    component: <OficinaDetalles />,
    key: 4,
    inMenu: false,
  },
  {
    name: "Asesor",
    route: "/asesor/:estado/:ciudad/:surcursal/:nombre",
    component: <Asesor />,
    key: 5,
    inMenu: false,
  },
  {
    name: "Seguro de daños",
    route: "/seguro-de-danos",
    component: <SeguroDeDanos />,
    key: 6,
    inMenu: false,
  },
  {
    name: "Seguro de daños",
    route: "/seguro-de-vida",
    component: <SeguroDeVida />,
    key: 7,
    inMenu: false,
  },
  {
    name: "Seguro de auto",
    route: "/seguro-de-auto",
    component: <SeguroDeAuto />,
    key: 8,
    inMenu: false,
  },
  {
    name: "Terminos y Condiciones",
    route: "/terminos-y-condiciones",
    component: <TerminosYCondiciones />,
    key: 9,
    inMenu: false,
  },
  {
    name: "Aviso de Privacidad",
    route: "/aviso-de-privacidad",
    component: <AvisoDePrivacidad />,
    key: 10,
    inMenu: false,
  },
];

export default routes;
