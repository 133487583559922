import Plantilla from "./plantilla";

export default function SeguroDeDanos() {
  return (
    <Plantilla>
      <div className="ejemplo">
        <p>Seguro de Daños</p>
      </div>
    </Plantilla>
  );
}
