/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "promessa/components/DefaultNavbar";
import DefaultFooter from "promessa/components/DefaultFooter";

// Routes
import routes from "promessa/routes";
import footerRoutes from "promessa/footer.routes";

// Images
import bgImage from "promessa/assets/images/banner-2_azul.jpg";
import bgImageMobile from "promessa/assets/images/mobile/banner_oficinas_mobile.jpg";

import { Card } from "@mui/material";

// Estados de la República
//import estadosJson from "promessa/assets/estados_federales.json";
//import ciudadesJson from "promessa/assets/ciudades.json";
import Dropdown from "promessa/pages/Oficinas/components/Dropdown";
import cotizaText from "promessa/assets/images/boton cotiza.png";

//import oficinasChihuahua from "promessa/assets/Chihuahua.json";
//import oficinasTamaulipas from "promessa/assets/Tamaulipas.json";

import { useEffect, useRef, useState } from "react";
import useStore from "promessa/store";
import { useParams } from "react-router-dom";

function Oficinas() {
  //const [ciudadesChihuahua, setCiudadesChihuahua] = useState([]);
  //const [ciudadesTamaulipas, setCiudadesTamaulipas] = useState([]);

  //const [estados, setEstados] = useState();
  const { accion } = useParams();
  const { estados, cargarEstados } = useStore();

  useEffect(() => {
    //setCiudadesChihuahua([...new Set(oficinasChihuahua.map((item) => item.Ciudad))]);
    //setCiudadesTamaulipas([...new Set(oficinasTamaulipas.map((item) => item.Ciudad))]);
    cargarEstados();
  }, []);

  useEffect(() => {
    if (accion == "estados") handleClick();
  }, [accion]);

  const [renderKey, setRenderKey] = useState(0);
  const estadosDivRef = useRef(null);

  useEffect(() => {
    if (renderKey > 0 && estadosDivRef.current != null) {
      setTimeout(() => {
        estadosDivRef.current.scrollIntoView({ behavior: "smooth" });
        setRenderKey(0);
      }, 100);
      // setRenderKey(0);
    }
  }, [renderKey]);

  const handleClick = () => {
    setRenderKey((prevKey) => prevKey + 1);
  };

  return (
    <div style={{ width: "100%" }}>
      <DefaultNavbar routes={routes} sticky transparent light />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundColor: "#225399",
          backgroundImage: { xs: ``, md: `url(${bgImage})` },
          backgroundSize: { xs: "cover", md: "cover" },
          backgroundPosition: { xs: "center center", md: "top" },
          backgroundRepeat: "no-repeat",
          display: "flex",
          placeItems: { xs: "flex-start", md: "center" },
          paddingTop: { xs: "120px", md: 0 }, // Adjust paddingTop to avoid conflict with navbar on mobile
          paddingBottom: { xs: "-100px", md: "" },
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "flex-start", md: "center" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            sx={{
              textAlign: { xs: "left", md: "left" },
              display: "flex",
              flexDirection: "column",
              paddingLeft: { xs: "16px", md: 0 }, // Add paddingLeft for mobile
            }}
          >
            <Grid item textAlign={{ xs: "center", md: "left" }}>
              <MKTypography
                variant="h1"
                color="white"
                className="text-container"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("sm")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Oficinas
              </MKTypography>
              <MKBox
                component="img"
                src={bgImageMobile}
                display={{ xs: "block", md: "none" }}
                width="100%"
                height="auto"
              />
              <MKTypography
                variant="h3"
                color="white"
                pr={{ xs: 0, md: 12, lg: 24, xl: 0 }}
                mt={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("sm")]: {
                    fontSize: size["lg"],
                  },
                })}
              >
                ¿Necesitas asistencia?
              </MKTypography>
              <MKTypography
                variant="h3"
                color="white"
                pr={{ xs: 0, md: 12, lg: 24, xl: 0 }}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("sm")]: {
                    fontSize: size["lg"],
                  },
                })}
              >
                Visita nuestra oficina en tu ciudad.
              </MKTypography>
              <MKBox
                mt={1}
                sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" } }}
              >
                <MKButton
                  onClick={handleClick}
                  variant="contained"
                  color="warning"
                  size="large"
                  sx={{
                    px: { xs: 5, md: 5 },
                    py: { xs: 1, md: 2 },
                    minWidth: "fit-content",
                    maxWidth: "fit-content",
                    flexShrink: 0,
                    color: "#4a2c00",
                  }}
                >
                  <img src={cotizaText} style={{ width: "60px" }} />
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        ref={estadosDivRef}
        sx={{
          p: 4,
          mx: { xs: 1, lg: 1 },
          mb: 2,
          mt: 2,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center", // Ensure space between the text and dropdown
            alignItems: "center", // Align items vertically centered
            flexWrap: "wrap",
          }}
        >
          <MKBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center the text vertically within its container
            }}
          >
            <MKTypography variant="h3">Contacta a tu Asesor Promessa</MKTypography>
            <MKTypography variant="subtitle1" sx={{ mt: 2 }}>
              Encuentra a tu asesor en tu ciudad
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxHeight: "100px",
          }}
        >
          {estados?.map((estado) => (
            <Estado key={estado.Estado_Id} estado={estado} />
          ))}
        </MKBox>
      </Card>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </div>
  );
}

function Estado(props) {
  //const [ciudades, setCiudades] = useState([]);
  const { ciudadesEnEstado, cargarCiudades } = useStore();

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    cargarCiudades(props.estado.Estado_Id);
  }, []);

  return (
    <Dropdown
      // eslint-disable-next-line react/prop-types
      data={ciudadesEnEstado[props.estado.Estado_Id]}
      itemKey="Nombre"
      route={"oficina-detalles/"}
      // eslint-disable-next-line react/prop-types
      label={props.estado.Estado}
    />
  );
}

export default Oficinas;
