import Plantilla from "./plantilla";

export default function TerminosYCondiciones() {
  return (
    <Plantilla>
      <div className="ejemplo">
        <h2>Términos y Condiciones</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc accumsan magna vel risus
          congue volutpat. Quisque venenatis venenatis lacus, ac scelerisque arcu tempor ut.
          Interdum et malesuada fames ac ante ipsum primis in faucibus. Morbi libero urna, interdum
          facilisis nulla sed, pulvinar sagittis lectus. Donec vehicula nibh ut dui placerat,
          consectetur pretium ante porta. Nam at eros sapien. Pellentesque justo nunc, malesuada ac
          euismod non, vestibulum suscipit risus. Integer tempus magna non cursus consectetur. Nam
          eget scelerisque turpis, id tempus lorem. Fusce lorem mi, ultrices dapibus ante eget,
          varius efficitur dolor. Duis cursus suscipit ante dapibus finibus.
        </p>
        <p style={{ marginTop: 10 }}>
          Praesent feugiat nunc sem, in dictum augue malesuada nec. In hac habitasse platea
          dictumst. Phasellus in laoreet lectus, eget convallis elit. Aliquam convallis ligula
          ultricies tortor hendrerit bibendum. Curabitur quis libero sed est placerat hendrerit. Sed
          sodales at libero nec condimentum. Curabitur consequat est magna, vehicula porttitor augue
          scelerisque ac. Maecenas porttitor eleifend dui, quis suscipit metus posuere nec. Aenean
          finibus, sapien ut pulvinar vestibulum, dolor leo tincidunt velit, eget laoreet justo
          tortor non libero.
        </p>
      </div>
    </Plantilla>
  );
}
