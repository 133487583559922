/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "promessa/components/DefaultNavbar";
import DefaultFooter from "promessa/components/DefaultFooter";

// Routes
import routes from "promessa/routes";
import footerRoutes from "promessa/footer.routes";

// Images
import bgImage from "promessa/assets/images/banner_home.jpg";
import bgImageMobile from "promessa/assets/images/mobile/banner_home_mobile.jpg";
import asegurateText from "promessa/assets/images/boton asegurate aqui.png";
import { Link } from "react-router-dom";

function PromessaHome() {
  return (
    <div style={{ width: "100%" }}>
      <DefaultNavbar routes={routes} sticky transparent light />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundColor: "#225399",
          backgroundImage: { xs: ``, md: `url(${bgImage})` },
          backgroundSize: { xs: "cover", md: "cover" },
          backgroundPosition: { xs: "center center", md: "top" },
          backgroundRepeat: "no-repeat",
          display: "flex",
          placeItems: { xs: "flex-start", md: "center" },
          paddingTop: { xs: "120px", md: 0 }, // Adjust paddingTop to avoid conflict with navbar on mobile
          paddingBottom: { xs: "-100px", md: "" },
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "flex-start", md: "center" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            sx={{
              textAlign: { xs: "left", md: "left" },
              display: "flex",
              flexDirection: "column",
              paddingLeft: { xs: "16px", md: 0 }, // Add paddingLeft for mobile
            }}
          >
            <Grid item textAlign={{ xs: "center", md: "left" }}>
              <MKTypography
                variant="h1"
                color="white"
                className="text-container"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("sm")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Bienvenido a
              </MKTypography>
              <div></div>
              <MKTypography
                variant="h1_it"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("sm")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Promessa
              </MKTypography>
              <MKBox
                component="img"
                src={bgImageMobile}
                display={{ xs: "block", md: "none" }}
                width="100%"
                height="auto"
              />
              <MKTypography
                variant="h3"
                color="white"
                pr={{ xs: 0, md: 12, lg: 24, xl: 0 }}
                mt={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("sm")]: {
                    fontSize: size["lg"],
                  },
                })}
              >
                Tu aliado en seguros.
              </MKTypography>
              <MKTypography
                variant="h3"
                color="white"
                pr={{ xs: 0, md: 12, lg: 24, xl: 0 }}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("sm")]: {
                    fontSize: size["lg"],
                  },
                })}
              >
                Descubre nuestras opciones
              </MKTypography>
              <MKTypography
                variant="h3"
                color="white"
                pr={{ xs: 0, md: 12, lg: 24, xl: 0 }}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("sm")]: {
                    fontSize: size["lg"],
                  },
                })}
              >
                para tu bienestar
              </MKTypography>
              <MKBox
                mt={1}
                sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" } }}
              >
                <Link to="/oficinas/principal">
                  <MKButton
                    variant="contained"
                    color="warning"
                    size="large"
                    sx={{
                      px: { xs: 5, md: 2 },
                      py: { xs: 1, md: 2 },
                      width: "auto",
                      minWidth: "fit-content",
                      maxWidth: "fit-content",
                      flexShrink: 0,
                      color: "#4a2c00",
                    }}
                  >
                    <img src={asegurateText} style={{ height: "16px" }} />
                  </MKButton>
                </Link>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </div>
  );
}

export default PromessaHome;
