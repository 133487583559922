import axios from "axios";
import { create } from "zustand";

const useStore = create((set, get) => ({
  estados: [], //[{"Estado_Id", "Estado"}]
  ciudadesEnEstado: {}, //ciudadesEnEstado[estado.Estado] -> {"Chihuahua" => {...}, "Tamaulipas" => {...} }
  sucursalesEnCiudad: {}, //sucursalesEnCiudad[ciudadesEnEstado['Chihuahua']]
  //asesoresTodos: [], // [{"id":"5","Estado_Id":"Chihuahua","Nombre":"Francisco Martinez Hernandez","Email":"franciscomartinezhernandez@promessa.com.mx","Oficina":"PROMOTORIA","Ciudad":"Hidalgo del Parral","Oficina_Id":"12","Imagen_Url":"https:\/\/i.ibb.co\/9V0F7xW\/socios-03.png","Imagen_BG_Url":"https:\/\/i.ibb.co\/jyp8dcr\/Banner-3web-2-2-ilustracion-3d-08.jpg","Video_Url":"https:\/\/drive.usercontent.google.com\/u\/0\/uc?id=11hbaqc_S6W5yRuHQAAIKMIoAYGdqLc7-&export=download","Biografia":"Pendiente","Estatus_Id":"Activo"}
  asesoresEnSucursal: {}, //asesoresEnSucursal[Oficina_Id] -> [{}.{}]
  asesoresEnSucursalPorNombre: {},
  //asesoresEnCiudad: {}, //asesoresEnCiudad[Ciudad] -> [{}.{}]
  //asesoresEnEstado: [], //asesoresEnEstado -> [{}.{}],
  cargarEstados: async () => {
    set({
      estados: (await axios.get("https://promessa.mpcreativo.com/api/?action=estados")).data.msg,
    });
  },
  cargarCiudades: async (estado) => {
    if (get().estados[estado]) return;

    const ciudadesPorAgregar = (
      await axios.get("https://promessa.mpcreativo.com/api/?action=ciudades&estado=" + estado)
    ).data.msg;

    set((state) => ({
      ciudadesEnEstado: {
        ...state.ciudadesEnEstado,
        [estado]: ciudadesPorAgregar,
      },
    }));
  },
  cargarSucursalesEnCiudad: async (ciudad) => {
    if (get().sucursalesEnCiudad[ciudad]) return;

    const sucursalesPorAgregar = (
      await axios.get("https://promessa.mpcreativo.com/api/?action=sucursales&ciudad=" + ciudad)
    ).data.msg;

    set((state) => ({
      sucursalesEnCiudad: {
        ...state.sucursalesEnCiudad,
        [ciudad]: sucursalesPorAgregar,
      },
    }));
  },

  cargarAsesoresEnSucursal: async (estado, ciudad, sucursal) => {
    const llave = estado + "," + ciudad + "," + sucursal;
    if (get().asesoresEnSucursal[llave]) get().asesoresEnSucursal[llave];

    const asesoresPorAgregar = // eslint-disable-next-line react/prop-types
      (
        await axios.get(
          "https://promessa.mpcreativo.com/api/?action=asesores&estado=" +
            estado +
            "&ciudad=" +
            ciudad +
            "&sucursal=" +
            sucursal
        )
      ).data.msg;

    set((state) => ({
      asesoresEnSucursal: {
        ...state.asesoresEnSucursal,
        [llave]: asesoresPorAgregar,
      },
    }));

    return asesoresPorAgregar;
  },
  cargarAsesoresEnSucursalPorNombre: async (estado, ciudad, sucursal, q) => {
    return (
      await axios.get(
        "https://promessa.mpcreativo.com/api/?action=asesores&estado=" +
          estado +
          "&ciudad=" +
          ciudad +
          "&oficina=" +
          sucursal +
          "&q=" +
          q
      )
    ).data.msg;
  },
}));

export default useStore;
