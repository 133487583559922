import { useState } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";

function Buscador({ asesores, setResultadosAsesores, todosAsesores }) {
  const [inputValue, setInputValue] = useState("");
  const [selectedAsesor, setSelectedAsesor] = useState(null);

  const inputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSelectAsesor = (event, value) => {
    setSelectedAsesor(value);
    if (value) {
      setResultadosAsesores([value]); // Mostrar solo el asesor seleccionado
    } else {
      setResultadosAsesores(todosAsesores);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevenir el comportamiento por defecto del Enter
      if (selectedAsesor) {
        setResultadosAsesores([selectedAsesor]); // Si hay asesor seleccionado, mostrarlo
      } else if (inputValue.length < 2) {
        alert("Inserta al menos 2 caracteres para la búsqueda.");
      }
    }
  };

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid container justifyContent="center">
          <Autocomplete
            options={asesores}
            getOptionLabel={(opt) => opt.Nombre}
            filterOptions={(options) =>
              options.filter(({ Nombre }) =>
                Nombre.toLowerCase().includes(inputValue.toLowerCase())
              )
            }
            onChange={handleSelectAsesor} // Cuando se selecciona un asesor
            onKeyDown={handleKeyDown} // Detecta el Enter
            renderInput={(params) => (
              <MKInput
                {...params}
                label="Buscar Asesores"
                value={inputValue}
                onChange={inputChange}
              />
            )}
            sx={{ width: 300 }}
          />
        </Grid>
      </Container>
    </MKBox>
  );
}

Buscador.propTypes = {
  asesores: PropTypes.array.isRequired,
  setResultadosAsesores: PropTypes.func.isRequired, // Para actualizar la lista de asesores mostrados
  todosAsesores: PropTypes.array.isRequired, // Para restaurar los asesores originales
};

export default Buscador;
